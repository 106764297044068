/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sort-keys */
import { APIDCN } from '@api/base';
import type { TAction, TDispatch } from '@models/types';

import { LandingActionType } from './types';

import type { LandingAction, LandingModel, TestimonialDataModel } from './types';
import type { KhazanahBlogDataModel } from '../blog/types';

const DUMMY_SOLUTIONS = [
  {
    id: '1',
    src: 'https://nextmail-api.dcnlink.com/media/b2b-10.svg',
    description: 'Airlines or GSA do not need to develop own distribution channels to serve travel agent or OTA. ALTEIRA as Software as a Service (SaaS) has provided a ready-to-use B2B platform that you can use today.',
    title: 'B2B Commerce'
  },
  {
    id: '2',
    src: 'https://nextmail-api.dcnlink.com/media/b2c-01.svg',
    description: 'B2C Commerce is a ready-to-use platform that airlines, GSAs, and wholesalers can use to offer direct services to B2B OTAs or individual customers. The complexity of the distribution system has been resolved by ALTEIRA. ',
    title: 'B2C Commerce'
  },
  {
    id: '3',
    src: 'https://nextmail-api.dcnlink.com/media/marketplace.svg',
    description: 'Do you want to sell airline tickets, hotels, visas, and buses using ALTEIRA web? Select ALTEIRA marketplace feature. You have the freedom to manage travel inventory from promotions, marketing, to sales.',
    title: 'Marketplace'
  }
  /*
   * {
   *   airlineId: '4',
   *   src: 'https://nextmail-api.dcnlink.com/media/etihad-airways-logo.svg',
   *   description: 'Maskapai nasional Uni Emirates Arab (UEA) ini dijuluki Permata dari Teluk Persia. Melayani penerbangan umrah dari Indonesia dengan satu kali transit di Abu Dhabi. Menjadi pilihan bagi yang ingin menyaksikan dan menikmati kemajuan Abu Dhabi dari dekat.'
   * },
   * {
   *   airlineId: '5',
   *   src: 'https://nextmail-api.dcnlink.com/media/emirates.svg',
   *   description: 'Dengan slogan Fly Better, maskapai ini menjadi pilihan operator umrah yang ingin memberikan pengalaman menikmati kemegahan Dubai bagi jamaahnya. Emirates melayani penerbangan dari Indonesia menuju Arab Saudi dengan sekali transit di Dubai.'
   * },
   * {
   *   airlineId: '6',
   *   src: 'https://nextmail-api.dcnlink.com/media/oman-air.svg',
   *   description: 'Maskapai Kesultanan Oman ini mulai melayani penerbangan dari Indonesia tujuan Muscat sejak 2014, termasuk di dalamnya penerbangan umrah. Salah satu terobosan maskapai ini antara lain menjadi maskapai pertama di dunia yang menyediakan layanan WiFi sejak 2010.'
   * },
   * {
   *   airlineId: '7',
   *   src: 'https://nextmail-api.dcnlink.com/media/turkish_airlines.svg',
   *   description: 'Turki memiliki destinasi wisata ikonik yang menjadi tujuan wisata maupun bagian dari program perjalanan umrah. Airlines dengan hub di Istanbul ini pilihan menarik untuk menjelajah pesona negeri Kekaisaran Ottoman di masa silam. '
   * },
   * {
   *   airlineId: '8',
   *   src: 'https://nextmail-api.dcnlink.com/media/scoot-logo.wine.svg',
   *   description: 'Scoot Tigerair Pte Ltd dari Singapura ini anak usaha dari Singapore Airlines. Maskapai LCC yang melayani penerbangan jarak menengah dan jauh ini juga melayani penerbangan umrah dari Indonesia dengan sekali transit di Singapura.'
   * },
   * {
   *   airlineId: '9',
   *   src: 'https://nextmail-api.dcnlink.com/media/srilankan_airlines.svg',
   *   description: 'Awalnya dikenal sebagai Air Lanka. Maskapai penerbangan milik Sri Lanka ini memiliki kode IATA UL dan memiliki basis serta hub di Bandara Bandaraneike. Maskapai ini menjadi alternatif group umrah dari Indonesia dengan sekali transit di Colombo.'
   * }
   */
];

const DUMMY_PRODUCTS = [
  {
    id: '1',
    src: 'https://nextmail-api.dcnlink.com/media/ri_plane-fill.svg',
    description: 'GIT or FIT for group travel include halal tours, Islamic tours, Holy Land, and Umrah flights.',
    title: 'Airlines'
  },
  {
    id: '2',
    src: 'https://nextmail-api.dcnlink.com/media/fa6-solid_hotel.svg',
    description: 'AlTEIRA makes it easier to select accommodation based on room capacity, like in umrah services. ',
    title: 'Hotel'
  },
  {
    id: '3',
    src: 'https://nextmail-api.dcnlink.com/media/ph_train-fill.svg',
    description: 'You can take various types of trains, like Haramain train, to make the Umrah pilgrimage easier.',
    title: 'Train'
  },
  {
    id: '4',
    src: 'https://nextmail-api.dcnlink.com/media/bus.svg',
    description: 'It\'s impossible to walk from one city to another. The bus is mandatory in your traveling.',
    title: 'Bus'
  },
  {
    id: '5',
    src: 'https://nextmail-api.dcnlink.com/media/solar_document-bold.svg',
    description: 'Especially for umrah trips, umrah operators can arrange visas for their pilgrims on this channel.',
    title: 'Visa'
  },
  {
    id: '6',
    src: 'https://nextmail-api.dcnlink.com/media/game-icons_meal.svg',
    description: 'You can order food like a souvenir, not just during the flight or at your destination.',
    title: 'Meal'
  }

];

const DUMMY_TESTIMONIAL: TestimonialDataModel[] = [
  {
    company: 'PT Annisa Almukaromah Almubarokah',
    description: '“Saya memesan tiket pertama kali di ALTEIRA untuk 40 seat. Prosesnya sangat mudah sekali. Kita juga bisa melihat pilihan harga dan alhamdulillah bersaing. Mudah-mudahan ini bisa membantu travel umrah lainnya.”',
    name: 'Imas RG',
    src: 'https://nextmail-api.dcnlink.com/media/testimoni_-_imas_rg_retouch.jpg',
    testimonialId: '1',
    position: 'Direktur Annisa Tours & Travel'
  },
  {
    company: 'PT Ramah Fista Internasional',
    description: '“Aplikasi group booking tiket umrah ALTEIRA mudah digunakan, user friendly, dan tidak rumit. Sangat membantu sekali. Patut digunakan oleh teman-teman travel umrah di Indonesia.”',
    name: 'Yudi Asmara',
    src: 'https://nextmail-api.dcnlink.com/media/testimoni_-_yudi_asmara_retouch.jpg',
    testimonialId: '2',
    position: 'Direktur Fistatour'
  },
  {
    company: 'Blue Ribbon Bags – USA',
    description: '“Kami sudah melihat dan berdikusi dengan manajemen ALTEIRA. Ini inovasi menarik karena berfokus pada penerbangan group.”',
    name: 'Conor Coyne',
    src: 'https://nextmail-api.dcnlink.com/media/testimoni_-_conor_coyne_retouch.jpg',
    testimonialId: '3',
    position: 'Business Development Manager S.E. Asia'
  },
  {
    company: 'PT Tiga Mitra Plus',
    description: '“Aplikasi di ALTEIRA simpel, tidak ribet untuk mendapatkan tiketnya. Jadi sangat mudah sekali. Saya mencoba dan alhamdulillah lancar. ”',
    name: 'Ulil Albab',
    src: 'https://nextmail-api.dcnlink.com/media/testimoni_-_ulil_albab_retouch.jpg',
    testimonialId: '4',
    position: 'Direktur 3MitraPlus'
  },
  {
    company: 'SV September 2017 - January 2022',
    description: '“Inovasi ALTEIRA ini mempermudah pemesanan tiket group bagi travel agent. Setiap inovasi yang mempermudah dan meningkatkan penjualan produk Saudia tentu harus didukung.”',
    name: 'Ala Jefri',
    src: 'https://nextmail-api.dcnlink.com/media/testimoni_-_ala_jefri_retouch.jpg',
    testimonialId: '5',
    position: 'CM Saudia Indonesia, Australia, & New Zealand '
  },
  {
    company: 'Kuala Lumpur - Malaysia',
    description: '“Inovasi ALTEIRA cukup menarik untuk layanan penerbangan group seperti umrah. Dengan kanal digitalnya, pasar yang dapat dijangkau lebih luas.”',
    name: 'Yazid Mohammed',
    src: 'https://nextmail-api.dcnlink.com/media/testimoni_-_yazid_mohammed_retouch.jpg',
    testimonialId: '6',
    position: 'Head of Commercial AMAL by Malaysia Airlines'
  }
];

const DUMMY_INSIGHT: KhazanahBlogDataModel[] =
  [
    {
      _id: 'articles/6765654',
      _key: '6765654',
      _rev: '_gfuRptq---',
      article: 'In 2012, the International Air Transport Association (IATA) introduced the New Distribution Capability (NDC) program, a data exchange standard aimed at replacing the outdated EDIFACT protocol used by GDSs for direct distribution of travel products. This marked a crucial step toward aligning airlines with a more retail-oriented model.',
      createdOn: '2023-06-14T06:47:22.151Z',
      img: 'https://nextmail-api.dcnlink.com/media/content.svg',
      published: true,
      thumbnail: 'https://nextmail-api.dcnlink.com/media/content.svg',
      title: 'The Impact of IATA\'s ONE Order Initiative'
    },
    {
      _id: 'articles/6814164',
      _key: '6814164',
      _rev: '_gYq29tC---',
      article: 'The hospitality and tourism industry got hit the hardest by COIVD-19 and it’s still in the path of recovery. Experts expect that it won’t recover completely until 2024. In Saudi Arabia, since the lockdown measures were loosened and the number of people vaccinated increased, life came back to restaurants, events, and sports venues. Hence the country is back on track to achieve its 2030 vision. Saudi\'s Vision 2030 looks to diversify Saudi Arabia\'s economy away from oil and focusing more on other sectors including increasing direct foreign investment, tourism, and the number of locals in the workforce.',
      createdOn: '2023-06-14T08:09:27.702Z',
      img: 'https://nextmail-api.dcnlink.com/media/content_(1).svg',
      published: true,
      thumbnail: 'https://nextmail-api.dcnlink.com/media/content_(1).svg',
      title: 'The Future of Hospitality Industry in Saudi Arabia'
    },
    {
      _id: 'articles/6879644',
      _key: '6879644',
      _rev: '_gJ2XoFO---',
      article: 'The New Distribution Capability (NDC) is a travel industry standard for distributing and retailing flight content. It is being developed by IATA (International Air Transportation Association), which recognized an opportunity to develop a new data exchange standard based on XML, allowing a better shopping, booking, and post-booking experience.',
      createdOn: '2023-06-15T08:08:39.636Z',
      img: 'https://nextmail-api.dcnlink.com/media/content_(2).svg',
      published: true,
      thumbnail: 'https://nextmail-api.dcnlink.com/media/content_(2).svg',
      title: 'What is changing in the industry with NDC & ONE Order?'
    }
  ];

const DUMMY_PROMO: KhazanahBlogDataModel[] =
  [
    {
      _id: 'articles/6765654',
      _key: '6765654',
      _rev: '_gfuRptq---',
      article: 'In 2012, the International Air Transport Association (IATA) introduced the New Distribution Capability (NDC) program, a data exchange standard aimed at replacing the outdated EDIFACT protocol used by GDSs for direct distribution of travel products. This marked a crucial step toward aligning airlines with a more retail-oriented model.',
      createdOn: '2023-06-14T06:47:22.151Z',
      img: 'https://nextmail-api.dcnlink.com/media/content_(3).svg',
      published: true,
      thumbnail: 'https://nextmail-api.dcnlink.com/media/content_(3).svg',
      title: 'We are offering a 20% discount for the purchase of 50 tickets. Grab it now!'
    },
    {
      _id: 'articles/6814164',
      _key: '6814164',
      _rev: '_gYq29tC---',
      article: 'The hospitality and tourism industry got hit the hardest by COIVD-19 and it’s still in the path of recovery. Experts expect that it won’t recover completely until 2024. In Saudi Arabia, since the lockdown measures were loosened and the number of people vaccinated increased, life came back to restaurants, events, and sports venues. Hence the country is back on track to achieve its 2030 vision. Saudi\'s Vision 2030 looks to diversify Saudi Arabia\'s economy away from oil and focusing more on other sectors including increasing direct foreign investment, tourism, and the number of locals in the workforce.',
      createdOn: '2023-06-14T08:09:27.702Z',
      img: 'https://nextmail-api.dcnlink.com/media/content_(4).svg',
      published: true,
      thumbnail: 'https://nextmail-api.dcnlink.com/media/content_(4).svg',
      title: 'We are providing a 30% discount for the purchase of 100 tickets. Take it now!'
    },
    {
      _id: 'articles/6879644',
      _key: '6879644',
      _rev: '_gJ2XoFO---',
      article: 'The New Distribution Capability (NDC) is a travel industry standard for distributing and retailing flight content. It is being developed by IATA (International Air Transportation Association), which recognized an opportunity to develop a new data exchange standard based on XML, allowing a better shopping, booking, and post-booking experience.',
      createdOn: '2023-06-15T08:08:39.636Z',
      img: 'https://nextmail-api.dcnlink.com/media/content_(5).svg',
      published: true,
      thumbnail: 'https://nextmail-api.dcnlink.com/media/content_(5).svg',
      title: 'We are extending a 40% discount for the purchase of 150 tickets. Grab it now!'
    }
  ];
const LandingDefault: LandingModel = {
  solutions: [],
  testimonial: [],
  banner: [],
  khazanah: [],
  promo: [],
  products: [],
  insight: [],
  promoCard: []
};

const LandingReducer = (state: LandingModel = LandingDefault, action: LandingAction): LandingModel => {
  switch (action.type) {
    case LandingActionType.GetSolutionsList:
      return { ...state, solutions: action.data };
    case LandingActionType.GetTestimonialList:
      return { ...state, testimonial: action.data };
    case LandingActionType.GetBannerList:
      return { ...state, banner: action.data };
    case LandingActionType.PostKhazanahList:
      return { ...state, khazanah: action.data };
    case LandingActionType.PostPromoCarousel:
      return { ...state, promo: action.data };
    case LandingActionType.GetProductsList:
      return { ...state, products: action.data };
    case LandingActionType.GetInsight:
      return { ...state, insight: action.data };
    case LandingActionType.GetPromoCard:
      return { ...state, promoCard: action.data };

    default:
      return { ...state };
  }
};

const LandingCommand = {
  getSolutionList: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_SOLUTIONS,
          type: LandingActionType.GetSolutionsList
        });
      });
    };
  },
  getProducts: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_PRODUCTS,
          type: LandingActionType.GetProductsList
        });
      });
    };
  },
  getInsight: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_INSIGHT,
          type: LandingActionType.GetInsight
        });
      });
    };
  },
  getPromoCard: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_PROMO,
          type: LandingActionType.GetPromoCard
        });
      });
    };
  },
  getTestimonialList: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return Promise.resolve().then(() => {
        dispatch({
          data: DUMMY_TESTIMONIAL,
          type: LandingActionType.GetTestimonialList
        });
      });
    };
  },
  getBannerList: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return APIDCN.get('/specpromotion/1.0/agent/promotion/banner-carousel')
        .then((response) => {
          dispatch({
            data: response.data.data,
            type: LandingActionType.GetBannerList
          });
        });
    };
  },
  postKhazanahList: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return APIDCN.post(
        '/khazanah/1.0/agent/articles-grid',
        {
          filter: {
            logic: '&&',
            filters: [
              {
                field: 'published',
                operator: 'eqb',
                value: 'true'
              }
            ]
          },
          pageSize: 10,
          skip: 0,
          sort: [
            {
              field: 'createdOn',
              dir: 'ASC'
            }
          ]
        }
      )
        .then((response) => {
          dispatch({
            data: response.data.output,
            type: LandingActionType.PostKhazanahList
          });
        });
    };
  },
  PostPromoCarousel: (): TAction<LandingAction, void> => {
    return (dispatch: TDispatch<LandingAction>) => {
      return APIDCN.post(
        '/promotion/1.0/agent/search-promotion',
        {
          onlyActive: false,
          searchPhrase: '',
          skip: 0,
          sort: '',
          take: 6
        }
      )
        .then((response) => {
          dispatch({
            data: response.data.results,
            type: LandingActionType.PostPromoCarousel
          });
        });
    };
  }
};

export { LandingCommand, LandingReducer, LandingDefault };
